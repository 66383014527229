const educationList = [
  {
    id: 1,
    school: 'Humber College',
    program: 'Web Development',
  },
  {
    id: 2,
    school: 'Greystone College',
    program: 'International Business Mng',
  },
  {
    id: 3,
    school: 'Anadolu University',
    program: 'Business Management',
  },
];

export default educationList;
