import React, { useState, useEffect } from 'react';
import './App.css';
import ColorSwitch from './components/colorSwitch/ColorSwitch';
import HomePage from './components/home/HomePage';
import Nav from './components/nav/Nav';
import Project from './components/projects/Project';
import Skills from './components/skills/Skills';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Services from './components/services/Services';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('showContent');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.notShow');
    hiddenElements.forEach((el) => observer.observe(el));
  }, []);

  const changeTheme = () => {
    setTheme(!theme);
  };
  return (
    <React.StrictMode>
      <div className={theme ? 'container_main white-theme' : 'container_main'}>
        <ColorSwitch switch={changeTheme} />
        <Nav className={'onTop'} />
        <div className='right_content'>
          <HomePage id={'home'} />
          {/* <Services id={'services'} /> */}
          <Project id={'projects'} />
          <Skills id={'skills'} />
          <About id={'about'} />
          <Contact id={'contact'} />
          <div className='copyright'>
            <small>Copyright © 2022 Dogukan Mere, All Rights Reserved.</small>{' '}
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default App;
