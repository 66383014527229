import React from 'react';
import styles from './About.module.css';

function EducationDetail(props) {
  return (
    <div className={styles.education}>
      {props.data.map((education) => {
        return (
          <div className={styles.education_inner} key={education.id}>
            <h5>{education.school}</h5>
            <p>{education.program}</p>
          </div>
        );
      })}
    </div>
  );
}

export default EducationDetail;
