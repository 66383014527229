import gameapplication from './img/gameapplication.JPG';
import oneseed from './img/oneseed.JPG';
import campproject from './img/campproject.JPG';
import weather from './img/weather.JPG';
import planttheseeds from './img/planttheseeds.png';
import discord from './img/discord.jpeg';

const projects = [
  {
    id: 1,
    title: 'Plant the Seeds Capstone Project',
    description:
      'This project was created to help both farmers and consumers by providing a service that both parties can benefit from. The system functions similarly to an e-commerce website.',
    skills: 'MERN | MongoDB | Express | React | Node.js ',
    img: planttheseeds,
    links: 'https://github.com/DogukanMere/Plant_the_Seeds_MERN',
    live: 'https://plant-the-seeds-client.vercel.app/',
  },
  {
    id: 2,
    title: 'OneSeed Project',
    description:
      'OneSeed project is an imaginary website that you can purchase any plant seed you want.',
    skills: 'Node.js | Pug | Express | CSS | JavaScript',
    img: oneseed,
    links: 'https://github.com/DogukanMere/OneSeed_Project',
    live: 'https://oneseed-project.onrender.com',
  },
  {
    id: 3,
    title: 'Discord Landing Page | Clone',
    description:
      'This project has been created just to showcase my React, Next.js, TypeScript, Tailwind CSS abilities. The project has been cloned by using original discord website (www.discord.com).',
    skills: 'React | Next.js | TypeScript | Tailwind CSS',
    img: discord,
    links: 'https://github.com/DogukanMere/discord-clone-project',
    live: 'https://discord-clone-ivory-delta.vercel.app/',
  },
  {
    id: 4,
    title: 'Game Application',
    description:
      'This project is created for an imaginary video game which I named "Into the Darkness". This is my passion project, and it was influenced by video games in general.',
    skills: 'C# | ASP.net framework',
    img: gameapplication,
    links: 'https://github.com/DogukanMere/GameApplication',
    live: '',
  },
  {
    id: 5,
    title: 'Campsite Cost Calculator',
    description:
      'This project was intended to estimate the cost of camping for a specific period of time based on information provided by users.',
    skills: 'HTML | CSS | JavaScript',
    img: campproject,
    links:
      'https://github.com/DogukanMere/Campsite-Cost-Calculator_Pet-Project',
    live: 'https://phenomenal-kitten-e124c4.netlify.app/',
  },
  {
    id: 6,
    title: 'Weather API Application',
    description:
      'Consuming OpenWeather API, I created a react app which display current weather condition for a city based on user input.',
    skills: 'React | CSS',
    img: weather,
    links: 'https://github.com/DogukanMere/weather-api-react',
    live: 'https://jovial-frangipane-a08e8d.netlify.app/',
  },
];

export default projects;
