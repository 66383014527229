import React from 'react';
import './NavItems.css';
import navList from '../../lists/nav/navList';

const NavItems = () => {
  return (
    <div className='nav-container-items'>
      <ul className='nav-items'>
        {navList.map((item) => {
          return (
            <li key={item.id}>
              <a href={`${item.ref}`}>{item.navItem}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavItems;
