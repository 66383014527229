import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from '../UI/Button';
import styles from './HomePage.module.css';

const HomePage = (props) => {
  return (
    <Container id={props.id}>
      <div className={styles.home_container}>
        <div className={styles.home_container_inner}>
          <div className={styles.header_img_container}>
            <div className={styles.header_img}></div>
          </div>
          <div className={styles.header_text}>
            <h1 className='ps-4'>I AM DOGUKAN MERE</h1>
            <h2>A WEB DEVELOPER</h2>
          </div>
        </div>
        <div className={styles.btn_home}>
          <Button href='#projects'>MY WORK</Button>
        </div>
      </div>
    </Container>
  );
};

export default HomePage;
