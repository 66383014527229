import React from 'react';
import Container from 'react-bootstrap/Container';
import AboutMe from './AboutMe';

const About = (props) => {
  return (
    <Container id={props.id}>
      <AboutMe />
    </Container>
  );
};

export default About;
