import React from 'react';
import Container from 'react-bootstrap/Container';
import NavSocial from '../nav/NavSocial';
import styles from './Contact.module.css';
import '../UI/SectionSetup.css';
import resume from './pdf/dogukan.pdf';

const Contact = (props) => {
  return (
    <Container id={props.id}>
      <div className={`${styles.contact} notShow`}>
        <div className={`${styles.contact_container}`}>
          <h3>CONTACT</h3>
          <p>
            Please get in touch with me if you have any questions or even just
            to say hi.
          </p>
        </div>
        <div className={styles.contact_detail}>
          <div className={styles.contact_email}>
            <div>
              <div className={styles.contact_each}>
                <i className='fa-solid fa-envelope'></i>
                <small>Email</small>
              </div>
              <a
                href='mailto:info@meredogukan.com'
                className={styles.contact_email_item}
              >
                Send email
              </a>
            </div>
          </div>
          <div>
            <div className={styles.contact_each}>
              <i className='fa-solid fa-location-dot'></i>
              <small>Address</small>
            </div>
            <strong>Toronto | Canada</strong>
          </div>
          <div>
            <div className={styles.contact_each}>
              <i className='fa-solid fa-globe'></i>
              <small>Social</small>
            </div>
            <div className={styles.social_follow}>
              <NavSocial />
            </div>
          </div>
          <div>
            <div className={styles.resume_section}>
              <i className='fa-solid fa-file'></i>
              <small>Resume</small>
            </div>
            <a
              className={styles.resume}
              href={resume}
              target='_blank'
              rel='noreferrer'
            >
              See Resume
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
