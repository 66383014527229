import React from 'react';
import styles from './Nav.module.css';
import NavSocial from './NavSocial';
import NavItems from './NavItems';
import NavbarHamburger from './NavbarHamburger';

const Nav = (props) => {
  return (
    <>
      <div className={styles.sticky_hamburger}>
        <NavbarHamburger className={styles.notshow} />
      </div>
      <div>
        <nav className={`${styles.nav} ${styles.onTop}`}>
          <div className={styles.logo}>
            <h1>
              <a href='#home'>DM</a>
            </h1>
          </div>
          <NavItems />
          <NavSocial />
        </nav>
      </div>
    </>
  );
};

export default Nav;
