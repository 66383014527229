import React, { useState } from 'react';
import styles from './Projects.module.css';

const Projects = ({ data }) => {
  const [appState, setState] = useState({
    activeProject: null,
    objects: data,
  });

  const toggleActive = (index) => {
    setState({ ...appState, activeProject: appState.objects[index] });
  };

  const toggleDeactive = (index) => {
    setState({ ...appState, activeProject: null });
  };

  const toggleActiveStyles = (index) => {
    if (appState.objects[index] === appState.activeProject) {
      return styles.active;
    } else {
      return '';
    }
  };

  return (
    <div className={`${styles.project_container}`}>
      {appState.objects.map((project, index) => {
        return (
          <div key={project.id} className={`${styles.projects_inner}`}>
            <figure>
              <img
                src={project.img}
                alt='project_img'
                onClick={() => toggleActive(index)}
              />
              <figcaption className={toggleActiveStyles(index)}>
                <i
                  className='fa-solid fa-xmark'
                  onClick={() => toggleDeactive(index)}
                ></i>
                <h4>{project.title}</h4>
                <p>{project.description}</p>
                <div className={styles.links}>
                  <span>
                    <a href={project.links} target='_blank' rel='noreferrer'>
                      <i className='fa-brands fa-github'></i>
                    </a>
                  </span>
                  {project.live && (
                    <span>
                      <a href={project.live} target='_blank' rel='noreferrer'>
                        <i className='fa-solid fa-globe'></i>
                      </a>
                    </span>
                  )}
                </div>
                <div className={styles.used_skills}>
                  <p>{project.skills}</p>
                </div>
              </figcaption>
            </figure>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
