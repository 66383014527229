import React from 'react';
import styles from './Skills.module.css';

function SkillList(props) {
  return (
    <div className={`${styles.skills_main_container}`}>
      <div className={styles.skills_inner}>
        {props.data.map((skill) => {
          return (
            <div className={styles.skills_each} key={skill.id}>
              <div className={styles.skills_cover}></div>
              <div className={styles.skill_img}>
                <img src={skill.icon} alt='skill icon' />
              </div>
              <div className={styles.skill_name}>
                <p>{skill.title}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SkillList;
