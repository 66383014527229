import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './Project.module.css';
import Projects from './Projects';
import projectList from '../../lists/projects/projectList';

const Project = (props) => {
  return (
    <Container id={props.id}>
      <div className={`${styles.project} notShow`}>
        <h3>PROJECTS</h3>
        <Projects data={projectList} />
      </div>
    </Container>
  );
};

export default Project;
