import React from 'react';
import styles from './About.module.css';
import EducationDetail from './EducationDetail';
import educationList from '../../lists/education/educationList';

function Education() {
  return (
    <div>
      <h4 className={styles.education_title}>Education</h4>
      <EducationDetail data={educationList} />
    </div>
  );
}

export default Education;
