import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import styles from './Skills.module.css';
import SkillList from './SkillList';
import skillList from '../../lists/skills/skillList';

const Skills = (props) => {
  return (
    <Container id={props.id}>
      <div className={`${styles.skills} notShow`}>
        <div className={styles.skills_container}>
          <h3>SKILLS</h3>
          <SkillList data={skillList} />
        </div>
      </div>
    </Container>
  );
};

export default Skills;
