const navList = [
  {
    id: 1,
    ref: '#home',
    navItem: 'HOME',
  },
  {
    id: 2,
    ref: '#services',
    navItem: 'SERVICES',
  },
  {
    id: 3,
    ref: '#projects',
    navItem: 'PROJECTS',
  },
  { id: 4, ref: '#skills', navItem: 'SKILLS' },
  {
    id: 5,
    ref: '#about',
    navItem: 'ABOUT',
  },
  {
    id: 6,
    ref: '#contact',
    navItem: 'CONTACT',
  },
];

export default navList;
