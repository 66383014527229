import React from 'react';
import styles from './ColorSwitch.module.css';

function ColorSwitch(props) {
  return (
    <div className={styles.switch_container}>
      <label htmlFor='switch' className={styles.switch_dark}>
        <input type='checkbox' id='switch' onClick={props.switch} />
        <span className={styles.check}>
          <i className='fa-solid fa-moon'></i>
          <i className='fa-solid fa-sun'></i>
        </span>
      </label>
    </div>
  );
}

export default ColorSwitch;
