import React from 'react';
import styles from './About.module.css';
import Education from './Education';

function AboutMe() {
  return (
    <>
      <div className={`${styles.about} notShow`}>
        <h3 className=''>ABOUT</h3>
        <div className={styles.about_container}>
          <div className={styles.about_me}>
            <p>
              Hello! My name is Dogukan, I am a Developer based in Canada. I
              became interested in web development in 2014 when I made the
              decision to learn Javascript in order to build a dynamic website
              for one of my friends.
            </p>
            <p>
              A person who is organized and gives close attention to details
              when solving problems. In my spare time, when I'm not creating
              content or writing code, I like to cook, read, and play video
              games.
            </p>
          </div>
        </div>
        <div className={styles.education_container}>
          <Education />
        </div>
      </div>
    </>
  );
}

export default AboutMe;
