import React from 'react';
import './NavSocial.css';

const NavSocial = () => {
  const socialList = [
    {
      id: 1,
      socialLink: 'https://www.instagram.com/dgknmr/',
      icon: 'fa-brands fa-instagram-square',
    },
    {
      id: 2,
      socialLink: 'https://www.linkedin.com/in/dogukanmere/',
      icon: 'fa-brands fa-linkedin',
    },
    {
      id: 3,
      socialLink: 'https://github.com/DogukanMere',
      icon: 'fa-brands fa-github',
    },
    {
      id: 4,
      socialLink: 'https://www.facebook.com/dogukanmere/',
      icon: 'fa-brands fa-facebook-square',
    },
  ];

  return (
    <div className='social-links'>
      {socialList.map((item) => {
        return (
          <div className='social-link' key={item.id}>
            <a href={item.socialLink} target='_blank' rel='noopener noreferrer'>
              Social
              <i className={item.icon}></i>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default NavSocial;
